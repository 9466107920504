/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import NewLoginCreateAccount from "../../../new-login-create-account/NewLoginCreateAccount";
import SignupDesktop from "./SignupDesktop";

export default function SignUpScreenDesktop({
  setStep,
  setUser,
  initialValues,
  step,
  accentColor,
}) {
  return (
    <div
      className="signup-screen-desktop"
      css={css`
        min-height: 100vh;
      `}
    >
      <SignupDesktop initialValues={initialValues} step={step} />
      <div
        className="container"
        css={css`
          width: 100%;
        `}
      >
        <div>
          <NewLoginCreateAccount
            country={"US"}
            setUser={setUser}
            siteArea={"ambassador"}
            setStep={setStep}
            accentColor={accentColor}
          />
        </div>
      </div>
    </div>
  );
}
