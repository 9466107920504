import React from "react";
import PassTier from "./PassTier";
import expand from "../../../assets/images/map_v2/expand_more.svg";

export const getAppUrl = () => {
  const hostname = window.location.hostname;

  if (hostname.includes("localhost")) {
    return "http://app.localhost:3000/";
  } else if (hostname.includes("inkind-staging")) {
    return "https://app.inkind-staging.com/";
  } else {
    return "https://app.inkind.com/";
  }
};

const BrandDetails = ({
  brandToUse,
  curLoc,
  tiers,
  cloudinaryPath,
  userLocErr,
  ctaDisplay,
  tags,
  getFeaturedTags,
  purchaseModeTiers,
  brands,
  totalLocationCount,
}) => {
  const [tagsText, setTagsText] = React.useState("");

  React.useEffect(() => {
    setTagsText(getFeaturedTags(brandToUse)?.join(" • ") ?? "");
  }, [tags, brandToUse]);

  const hero =
    brandToUse?.branding.hero_image.value.indexOf(".png") !== -1
      ? brandToUse?.branding.hero_image.value.replace(".png", ".jpg")
      : brandToUse?.branding.hero_image.value;

  const getBrandsNum = () => {
    let locsNum = 0;
    const sameGroupBrands =
      brands &&
      Object.values(brands).filter((brand) => {
        return brandToUse.group_id === brand.group_id;
      });
    sameGroupBrands.forEach((brand) => {
      locsNum = locsNum + brand.location_ids?.length;
    });
    return locsNum;
  };

  const [brandsNum] = React.useState(getBrandsNum());
  return (
    <div className="details-container">
      <div
        className="loc-tile"
        style={{
          backgroundImage: `url(${cloudinaryPath}/b_black,o_67,c_fit,w_800/${hero})`,
        }}
      >
        <div
          className="loc-logo"
          style={{
            backgroundImage: `url(${cloudinaryPath}/w_250/${brandToUse?.branding.logo.dark_mode})`,
          }}
        />
      </div>
      <div className="text-container">
        <div className="loc-header">{brandToUse?.name}</div>
        <div className="loc-tags">{tagsText}</div>
        <div className="loc-distance">
          {curLoc?.location?.address}, {curLoc?.location?.city},{" "}
          {curLoc?.location?.state ?? ""} {curLoc?.location?.zip_code ?? ""}
        </div>
        <div
          className="loc-distance"
          style={{ display: !!userLocErr ? "none" : "block" }}
        >
          {curLoc?.staticDistance + " Miles Away"}
        </div>
      </div>
      <div className="summary-container">{brandToUse?.branding.summary}</div>
      {brandToUse?.branding?.gallery?.images?.length > 0 && (
        <div className="discovery-location-gallery-container">
          <div className="discovery-location-gallery">
            {brandToUse?.branding?.gallery?.images?.map((image, i) => {
              return (
                <div
                  className="discovery-image"
                  key={i}
                  style={{
                    backgroundImage: `url(${cloudinaryPath}/b_black,c_fit,w_250/${image})`,
                  }}
                ></div>
              );
            })}
          </div>
        </div>
      )}

      {ctaDisplay !== "none" && !!purchaseModeTiers ? (
        <div className="bonus-outer">
          <div className="bonus-container">
            <div className="loc-header">Get a dining bonus here</div>
            Add a balance to get a bonus, the more you spend the larger bonus
            you’ll receive! Claim your balance here and hundreds of other inKind
            partner locations.
          </div>
          <div className="tiers-container">
            {tiers?.map((tier) => {
              let useBonus =
                String(tier.bonus_amount).slice(-1) === "6" ||
                String(tier.bonus_amount).slice(-1) === "1"
                  ? tier.bonus_amount - 1
                  : tier.bonus_amount;
              return (
                <PassTier
                  key={tier.ico_slug}
                  payAmount={tier.pay_amount}
                  getAmount={tier.get_amount}
                  bonusAmount={useBonus}
                  icoId={tier.ico_slug}
                />
              );
            })}
          </div>
          <div className="decorated">
            <span>OR</span>
          </div>
          <a href={curLoc?.purchase_page_link} target="blank">
            <div className="map-pp-button">
              Shop individual house accounts
              <div className="pp-subtext">
                Only redeemable at {brandsNum}{" "}
                {brandsNum.length === 1 ? "location" : "locations"}
              </div>
            </div>
          </a>
        </div>
      ) : ctaDisplay !== "none" && !purchaseModeTiers ? (
        <div className="bonus-outer">
          <div className="map-subscribe-container">
            <div className="map-subscribe-and-earn">
              Get <span className="highlight">20% back</span> here
            </div>
            <div>
              Enjoy up to 20% back whenever you try one of our{" "}
              {totalLocationCount}+ locations using the inKind app
            </div>
            <a href={getAppUrl()} target="blank" className="download-button">
              <div>Download Now</div>
              <img src={expand} alt="Arrow icon" className="arrow" />
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default BrandDetails;
