import React from "react";
import fetch from "isomorphic-fetch";
import expand from "../../../assets/images/map_v2/expand_more.svg";
import blackOval from "../../../assets/images/map_v2/black-ovall.svg";
import blackOval2 from "../../../assets/images/map_v2/black-ovall-2.svg";
import downArrow from "../../../assets/images/map_v2/down-arrow-bl.svg";
import PropTypes from "prop-types";

import { motion, AnimatePresence } from "framer-motion";

import BrandDetails from "./BrandDetails";
import BrandInfo from "./BrandInfo";
import PassPopup from "./PassPopup";
import MapHeaderContainer from "./MapHeaderContainer";
import BackSection from "./BackSection";
import ZoomBox from "./ZoomBox";
import withEventsLogger from "@/events/withEventsLogger";
import useDeviceId from "@/events/useDeviceId";
import useSessionId from "@/events/useSessionId";

const App = ({
  tiers,
  cloudinaryPath,
  widget,
  enterprise,
  totalLocationCount,
  logEvents,
  brandSlug = "",
  showSearchBox = true,
  showSidebar = true,
  showZoombox = true,
  isHamburgerRequired = true,
  showPassPopup = true,
}) => {
  const oval = require(
    `../../../assets/images/enterprise/${enterprise}/map_v2/oval.svg`,
  );
  const logo = require(
    `../../../assets/images/enterprise/${enterprise}/icons/logo.png`,
  );
  //state
  let [userLocation, setUserLocation] = React.useState(null);
  let [staticLocation, setStaticLocation] = React.useState(null);
  let [locations, _setLocations] = React.useState([]);
  let [initLocations, setInitLocations] = React.useState([]);
  let [brands, setBrands] = React.useState(null);
  let [map, setMap] = React.useState(null);
  let [listState, _setListState] = React.useState(1);
  let [curBrand, setCurBrand] = React.useState(0);
  let [curLoc, setCurLoc] = React.useState(null);
  let [curMarker, _setCurMarker] = React.useState(null);
  let [markersState, setMarkersState] = React.useState([]);
  let [hamburgerState, _setHamburgerState] = React.useState(false);
  let [infoOpenMobile, _setInfoOpenMobile] = React.useState(false);
  let [enteredThroughButton, setEnteredThroughButton] = React.useState(false);
  let [popUpOpen, setPopUpOpen] = React.useState(true);
  let [isLoaded, setIsLoaded] = React.useState(false);
  let [userLocErr, setUserLocErr] = React.useState(false);
  let [filteredLocations, _setFilteredLocations] = React.useState([]);
  let [didRun, setDidRun] = React.useState(false);
  let [hiddenLength, setHiddenLength] = React.useState(0);
  let [tags, setTags] = React.useState(null);
  let [viewCenter, setViewCenter] = React.useState(null);
  let loadingDetails = false;
  let counter = 0;
  let [selectedCuisines, setSelectedCuisines] = React.useState(null);
  let [selectedMoods, setSelectedMoods] = React.useState(null);
  let [cuisinesIsOpen, setCuisinesIsOpen] = React.useState(false);
  let [moodsIsOpen, setMoodsIsOpen] = React.useState(false);
  let [initSelectedTag, setInitSelectedTag] = React.useState(false);
  let [showBonusBox, _setShowBonusBox] = React.useState(true);
  let [curWidth, setCurWidth] = React.useState(
    window.innerWidth >= 800 ? true : false,
  );
  let [placeHolder, setPlaceHolder] = React.useState(
    window.innerWidth <= 600 ? "Search..." : "Search city or venue...",
  );
  const [inkindStateName, setInkindStateName] = React.useState(enterprise);
  const [inkindStateDomain, setInkindStateDomain] = React.useState(
    "https://app." + enterprise + ".com",
  );

  let ikname =
    inkindStateName === "inkind"
      ? inkindStateName.replace("k", "K")
      : inkindStateName;

  const isBaseEnterprise = enterprise.toUpperCase() === "INKIND" || !enterprise;
  //ref handling
  const deviceId = useDeviceId();
  const sessionId = useSessionId();
  const curMarkerRef = React.useRef(curMarker);
  const showBonusBoxRef = React.useRef(showBonusBox);
  const hamburgerStateRef = React.useRef(hamburgerState);
  const infoOpenMobileRef = React.useRef(infoOpenMobile);
  const locationsRef = React.useRef(locations);
  const filteredLocationsRef = React.useRef(filteredLocations);
  const queryParams = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );
  const color = queryParams.colorMode;
  const showDeliveryOnly = queryParams.tag === "delivery";
  const purchaseModeTiers =
    queryParams.purchaseMode === "tiers" && !!isBaseEnterprise;
  const colorMode =
    color === "light" ? "light" : color === "black" ? "black" : "dark";
  const listStateRef = React.useRef(listState);

  const handleAmplitude = async (eventName, eventProperties) => {
    await logEvents([
      {
        session_id: sessionId,
        event_name: eventName,
        device_id: deviceId,
        event_properties: {
          ...eventProperties,
        },
      },
    ]);
  };

  const setHamburgerState = (data) => {
    hamburgerStateRef.current = data;
    _setHamburgerState(data);
  };

  const setShowBonusBox = (data) => {
    showBonusBoxRef.current = data;
    _setShowBonusBox(data);
  };

  const setInfoOpenMobile = (data) => {
    infoOpenMobileRef.current = data;
    _setInfoOpenMobile(data);
  };

  const setListState = (data) => {
    listStateRef.current = data;
    _setListState(data);
  };

  const setLocations = (data) => {
    locationsRef.current = data;
    _setLocations(data);
  };

  const setFilteredLocations = (data) => {
    const hiddenBrands = document.getElementsByClassName("mobile-hide-info");
    filteredLocationsRef.current = data;
    _setFilteredLocations(data);
  };

  const setCurMarker = (data) => {
    curMarkerRef.current = data;
    _setCurMarker(data);
  };

  //other global params
  const usedBrandsObj = {};
  const isPassPage = queryParams.pass === "true";
  const ctaDisplay = !isBaseEnterprise ? "none" : queryParams.ctaDisplay;

  //React effects
  React.useEffect(() => {
    handleStart();
  }, []);

  React.useEffect(() => {
    handleListStateChange();
  }, [listState]);

  React.useEffect(() => {
    handleInitSelected();
  }, [tags]);

  React.useEffect(() => {
    handleTagsChange();
  }, [selectedCuisines, selectedMoods]);

  React.useEffect(() => {
    handleBrandChange();
  }, [curBrand]);

  React.useEffect(() => {
    if (showSearchBox) handleOpenModal();
  }, [infoOpenMobile]);

  //backup user location functionality
  React.useEffect(() => {
    handleUserLocationChange();
  }, [userLocation]);

  React.useEffect(() => {
    if (!!map && curWidth === true) {
      changeCenterOnResize(map, map.getCenter(), "larger");
    } else if (!!map) {
      changeCenterOnResize(map, map.getCenter(), "smaller");
    }
  }, [curWidth]);

  //get map data once user location is established
  React.useEffect(() => {
    handleMapChange();
  }, [map, brandSlug]);

  //build search functionality
  React.useEffect(() => {
    handleMarkersStateChange();
  }, [markersState]);

  //change UI to show selected marker as black
  React.useEffect(() => {
    handleMarkerChange();
  }, [curMarker]);

  //functions
  const debounce = (a, b, c) => {
    let d;
    return () => {
      let e = this,
        f = [a, b];
      clearTimeout(d),
        (d = setTimeout(() => {
          (d = null), c || a.apply(e, f);
        }, b)),
        c && !d && a.apply(e, f);
    };
  };

  const handleOpenModal = () => {
    let infoContainer = document.getElementsByClassName("info-container")[0];
    if (!infoOpenMobile) {
      setEnteredThroughButton(false);
      infoContainer.classList.remove("visible");
      setTimeout(() => {
        infoContainer.style.display = "none";
      }, 500);
    } else {
      infoContainer.style.display = "block";
      setTimeout(() => {
        infoContainer.classList.add("visible");
      }, 50);
    }
  };

  const handleBrandChange = () => {
    let container = document.getElementsByClassName("details-container")[0];
    if (!!container) {
      container.style.opacity = 0;
      setTimeout(() => {
        container.style.transition = "opacity .5s";
        container.style.opacity = 1;
        setTimeout(() => {
          container.style.transition = "";
        }, 500);
      }, 100);
    }
  };

  const handleBonusScroll = () => {
    const scrollTarget = !!purchaseModeTiers
      ? document.getElementsByClassName("bonus-container")[0]
      : document.getElementsByClassName("map-subscribe-container")[0];
    const scrollHeight =
      document.getElementsByClassName("bonus-outer")[0]?.offsetHeight;
    const scrollElement = document.getElementsByClassName(
      "info-container-inner",
    )[0];
    const scrollToLocation =
      document.getElementsByClassName("brand-info")[0]?.offsetHeight +
      8 -
      scrollHeight;
    scrollElement.scrollTo({
      top: scrollToLocation,
      left: 100,
      behavior: "smooth",
    });
  };

  const handleInitSelected = () => {
    const startTag = queryParams.tag;
    if (!!startTag && !!tags && !initSelectedTag) {
      const tagsArr = Object.values(tags);
      for (let i = 0; i < tagsArr.length; i++) {
        if (tagsArr[i].slug === startTag) {
          setInitSelectedTag(tagsArr[i]);
          break;
        }
      }
    }
  };

  const handleListStateChange = () => {
    let container =
      window.innerWidth <= 800
        ? document.getElementsByClassName("map-outside")[0]
        : document.getElementsByClassName("info-container-inner")[0];
    if (!!container) {
      container.scrollTop = 0;
    }
  };

  const handleCurMarker = (data) => {
    //only change marker if selected marker is different
    if (data?.location?.name === curMarkerRef?.current?.location?.name) {
      return;
    } else {
      curMarkerRef?.current?.setZIndex(curMarkerRef?.current?.saveZIndex);
      curMarkerRef?.current?.setIcon({
        url: oval,
        labelOrigin: new google.maps.Point(15.5, 14),
      });
      setCurMarker(data);
    }
  };

  const handleUserLocationChange = () => {
    if (!!userLocation && !userLocation.isSearch && !userLocation.isRecenter) {
      if (
        userLocation === "not found" ||
        !userLocation.location ||
        userLocation?.location?.length === 0
      ) {
        let location = {};
        navigator.geolocation.getCurrentPosition(
          (position) => {
            location.location = [
              position.coords.latitude,
              position.coords.longitude,
            ];
            setUserLocation(location);
            setStaticLocation(location);
          },
          (error) => {
            location.location = [40.73, -74];
            setUserLocation(location);
            setStaticLocation(location);
            setUserLocErr(true);
          },
        );
      } else if (!userLocation.isSearch) {
        handleSetMap();
      }
    } else {
      handleMoveCenter();
    }
  };

  const handleHeight = debounce(() => {
    const innerHeight = String(window.innerHeight) + "px";
    document.getElementsByClassName("outer")[0].style.height = innerHeight;
    if (window.innerWidth <= 600) {
      setPlaceHolder("Search...");
    } else {
      setPlaceHolder("Search city or venue...");
    }
    setCurWidth(window.innerWidth >= 800 ? true : false);
  }, 300);

  const handleScrollShow = () => {
    const lineToUse = !!purchaseModeTiers
      ? document.getElementsByClassName("map-tier")[1]?.getBoundingClientRect()
          .bottom
      : document
          .getElementsByClassName("map-subscribe-and-earn")[0]
          ?.getBoundingClientRect().bottom;
    const isBelowLine =
      document.querySelector(".info-container-inner").getBoundingClientRect()
        .bottom -
        60 >
      lineToUse;
    if (!!isBelowLine && showBonusBoxRef.current === true) {
      setShowBonusBox(false);
    } else if (!isBelowLine && showBonusBoxRef.current === false) {
      setShowBonusBox(true);
    }
  };

  const handleMoveCenter = debounce(() => {
    if (locations?.length > 0) {
      handleLocsAndMarkers(locations, false);
    }
  }, 600);

  const handleMapChange = () => {
    if (!!userLocation && !!map) {
      const url = window.location.hostname.includes("localhost")
        ? "http://app.localhost:3000/"
        : window.location.hostname.includes("inkind-staging")
          ? "https://app.inkind-staging.com/"
          : "https://app.inkind.com/";
      fetch(
        url + "api/v5/map" + `${brandSlug ? `?brand_slugs=${brandSlug}` : ""}`,
      )
        .then((response) => response.json())
        .then((data) => {
          const mapContainer = document.getElementById("map-container");
          if (!mapContainer) {
            return;
          }
          markersState.forEach((marker) => marker.setMap(null));
          setMarkersState([]);
          handleSuccess(data);
        })
        .catch((err) => console.log(err));
    }

    window.addEventListener("resize", handleHeight);

    if (showSearchBox) {
      document
        .querySelector(".info-container-inner")
        .addEventListener("scroll", () => {
          if (listStateRef.current === 2 && ctaDisplay !== "none") {
            handleScrollShow();
          }
        });
    }

    if (!!map) {
      map.addListener("tilesloaded", () => {
        const toAddClass = document.getElementsByClassName("pac-container")[0];
        if (!!toAddClass) {
          toAddClass?.classList.add(
            widget === true && colorMode === "light"
              ? "light-pac-container"
              : colorMode === "black"
                ? "black-pac-container"
                : "dark-pac-container",
          );
        }
      });

      map.addListener("drag", () => {
        let newLoc = {};
        newLoc.isSearch = false;
        newLoc.isRecenter = true;
        newLoc.isLoc = true;
        newLoc.location = [map.getCenter().lat(), map.getCenter().lng()];
        setUserLocation(newLoc);
      });
      map.addListener("dragend", () => {
        handleAmplitude("click_web_map_content");
        setViewCenter(null);
      });
    } else {
      handleHeight();
    }
  };

  const handleMarkerChange = () => {
    if (!!curMarker) {
      curMarker.setZIndex(1000);
      curMarker.setIcon({
        url: !!curMarker.isMulti ? blackOval : blackOval2,
        labelOrigin: new google.maps.Point(15.5, 14),
      });
    }
  };

  const handleMarkersStateChange = () => {
    if (markersState?.length > 0 && didRun === false) {
      setDidRun(true);
      if (showSearchBox) initAutoComplete();
    }
  };

  const handleStart = () => {
    let googleMaps = document.createElement("script");
    googleMaps.async = true;
    googleMaps.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCHGODsYs-DA5vf_G64xrllxB9HGo9zmwg&v=weekly&libraries=places,geometry";

    let cheapRuler = document.createElement("script");
    cheapRuler.async = true;
    cheapRuler.src = "https://unpkg.com/cheap-ruler@3.0.1/cheap-ruler.min.js";

    let body = document.getElementsByTagName("body")[0];
    body.appendChild(googleMaps, body);
    googleMaps.onload = () => {
      body.appendChild(cheapRuler, body);
      cheapRuler.onload = () => {
        handleInit();
      };
    };
  };

  const getFeaturedTags = (tagsBrand) => {
    if (!tagsBrand) {
      return;
    }
    let tagsArr = [];
    tagsBrand.tags.forEach((tag) => {
      if (tag.featured === true) {
        tagsArr.push(tags[tag?.id]?.name);
      }
    });
    return tagsArr;
  };

  //go back to list state from individual location
  const handleBack = () => {
    const notMulti = filteredLocations.length === locations.length;
    setCurLoc(null);
    handleCurMarker(!!notMulti ? null : curMarker, notMulti);
    setListState(1);
    setCurBrand(0);
    if (!enteredThroughButton && !!notMulti) {
      setInfoOpenMobile(false);
    }
  };

  //exit from multimarker view to standard list view
  const handleExitMulti = () => {
    setFilteredLocations(locations);
    handleCurMarker(null);
    if (!enteredThroughButton) {
      setInfoOpenMobile(false);
    }
  };

  //build map
  const handleSetMap = () => {
    const mapContainer = document.getElementById("map-container");
    const latlng = new google.maps.LatLng(
      userLocation.location[0],
      userLocation.location[1],
    );
    const mapNew = new google.maps.Map(mapContainer, {
      center: {
        lat: userLocation.location[0],
        lng: userLocation.location[1],
      },
      zoom: 8,
      clickableIcons: false,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      gestureHandling: widget === true ? "auto" : "greedy",
    });

    google.maps.event.addListener(mapNew, "zoom_changed", function () {
      handleAmplitude("click_web_map_content");
    });
    google.maps.event.addListener(mapNew, "click", () => {
      handleAmplitude("click_web_map_content");
    });
    google.maps.event.addListenerOnce(mapNew, "idle", () => {
      // map is ready
      setCenter(latlng, mapNew);
    });

    const noPoi = [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
    ];
    mapNew.setOptions({ styles: noPoi });
    setMap(mapNew);
    setIsLoaded(true);
  };

  const handleFormatting = (tag) => {
    let spacedTag = tag.replace(/_/g, " ");
    return spacedTag.charAt(0).toUpperCase() + spacedTag.slice(1);
  };

  const handleTagsChange = () => {
    setListState(1);
    setCurLoc(null);
    handleCurMarker(null);
    setCurBrand(0);
    setInfoOpenMobile(false);

    if (!!selectedCuisines) {
      const newLocs = initLocations.filter((loc) =>
        brands[loc.brand_id].tags.some((tag) =>
          selectedCuisines.tags.includes(tag.id),
        ),
      );
      for (let i = 0; i < markersState.length; i++) {
        markersState[i].setMap(null);
      }
      setMarkersState([]);
      const newBrandsObj = {};
      for (let i = 0; i < newLocs.length; i++) {
        newBrandsObj[newLocs[i].brand_slug] = 1;
      }
      const newBrandsLength = Object.keys(newBrandsObj).length - 15;
      setHiddenLength(
        newBrandsLength < 1
          ? null
          : newBrandsLength < 15
            ? newBrandsLength
            : 15,
      );
      handleLocsAndMarkers(newLocs, true);
      setFilteredLocations(newLocs);
    } else {
      for (let i = 0; i < markersState.length; i++) {
        markersState[i].setMap(null);
      }
      !!brands ? setHiddenLength(Object.keys(brands).length - 15) : null;
      handleLocsAndMarkers(initLocations, true);
    }
  };

  //filter locations based on selected params
  const handleFilter = (field, value, locs) => {
    let filtered = [];
    for (let i = 0; i < locs.length; i++) {
      if (locs[i][field] === value) {
        filtered.push(locs[i]);
      }
    }
    setFilteredLocations(filtered);
  };

  const handleCenterSetting = (mapProj, useMap, latlng, offset) => {
    let offsetx =
      window.innerWidth >= 800
        ? document?.getElementsByClassName("info-container")[0]?.offsetWidth /
            2 +
          12
        : 0;
    let offsety = window.innerWidth >= 800 ? 0 : 102;
    if (!showSearchBox && !showSidebar && !showZoombox && !showPassPopup) {
      offsetx = 0;
      offsety = 0;
    }

    const isInstance = latlng instanceof google.maps.LatLng;
    const point1 = mapProj?.fromLatLngToPoint(
      isInstance ? latlng : useMap?.getCenter(),
    );
    if (offset === "negative") {
      offsetx = offsetx * -1;
      offsety = offsety * -1;
    } else if (offset === "offset") {
      offsetx = offsetx * 0.5;
      offsety = offsety * -0.5;
    }
    const point2 = new google.maps.Point(
      (typeof offsetx == "number" ? offsetx : 0) /
        Math.pow(2, useMap.getZoom()) || 0,
      (typeof offsety == "number" ? offsety : 0) /
        Math.pow(2, useMap.getZoom()) || 0,
    );
    const center = mapProj?.fromPointToLatLng(
      new google.maps.Point(point1.x - point2.x, point1.y + point2.y),
    );
    useMap.panTo(center);
  };

  const changeCenterOnResize = (useMap, latlng, type) => {
    const mapProj = useMap.getProjection();

    if (!mapProj) {
      console.error("Projection not found.");
      return;
    }

    const offsetx = type === "smaller" ? -195 : 195;
    const offsety = type === "smaller" ? 102 : -102;

    const isInstance = latlng instanceof google.maps.LatLng;
    const point1 = mapProj?.fromLatLngToPoint(
      isInstance ? latlng : useMap?.getCenter(),
    );

    if (!point1) {
      console.error("Invalid point1 calculation.");
      return;
    }

    // Calculate offset in map projection points
    const zoomLevel = useMap.getZoom();
    const zoomFactor = Math.pow(
      2,
      typeof zoomLevel === "number" ? zoomLevel : 1,
    );
    const point2 = new google.maps.Point(
      offsetx / zoomFactor,
      offsety / zoomFactor,
    );

    const newCenterPoint = new google.maps.Point(
      point1.x - point2.x,
      point1.y + point2.y,
    );
    const center = mapProj?.fromPointToLatLng(newCenterPoint);

    // Check if the new center is valid before panning
    if (center && center instanceof google.maps.LatLng) {
      useMap.panTo(center);
    } else {
      console.error("Center is invalid, check calculations.");
    }
  };

  const getTrueCenter = (latlng, useMap) => {
    // Get projection
    const mapProj = useMap.getProjection();
    let offsetx =
      window.innerWidth >= 800
        ? (document?.getElementsByClassName("info-container")[0]?.offsetWidth /
            2 +
            12) *
          -1
        : 0;
    let offsety = window.innerWidth >= 800 ? 0 : -102;
    if (!showSearchBox && !showSidebar && !showZoombox && !showPassPopup) {
      offsetx = 0;
      offsety = 0;
    }
    const isInstance = latlng instanceof google.maps.LatLng;
    const point1 = mapProj?.fromLatLngToPoint(
      isInstance ? latlng : useMap?.getCenter(),
    );
    const point2 = new google.maps.Point(
      (typeof offsetx == "number" ? offsetx : 0) /
        Math.pow(2, useMap.getZoom()) || 0,
      (typeof offsety == "number" ? offsety : 0) /
        Math.pow(2, useMap.getZoom()) || 0,
    );
    const center = mapProj?.fromPointToLatLng(
      new google.maps.Point(point1.x - point2.x, point1.y + point2.y),
    );
    return center;
  };

  const setCenter = (latlng, passedMap, offset) => {
    setViewCenter(latlng);
    const useMap = !!passedMap ? passedMap : map;
    let mapProj = useMap.getProjection();

    if (!mapProj) {
      google.maps.event.addListenerOnce(useMap, "projection_changed", () => {
        mapProj = useMap.getProjection();
        handleCenterSetting(mapProj, useMap, latlng, offset);
      });
    } else {
      handleCenterSetting(mapProj, useMap, latlng, offset);
    }
  };

  const handleBrandMouseIn = (location, locMarker) => {
    if (!loadingDetails) {
      locMarker.setZIndex(1000);
      locMarker.setIcon({
        url: !!locMarker.isMulti ? blackOval : blackOval2,
        labelOrigin: new google.maps.Point(15.5, 14),
      });
    }
  };

  const handleBrandMouseOut = (location, locMarker) => {
    if (
      !loadingDetails &&
      locMarker.locName !== curMarkerRef?.current?.locName
    ) {
      locMarker.setZIndex(locMarker.saveZIndex);
      locMarker.setIcon({
        url: oval,
        labelOrigin: new google.maps.Point(15.5, 14),
      });
    }
  };

  const handleShowMoreBrands = () => {
    const hiddenBrands = document.getElementsByClassName("mobile-hide-info");
    const lengthToUse = hiddenBrands.length - 15;
    while (hiddenBrands.length > lengthToUse && hiddenBrands.length > 0) {
      hiddenBrands[0].classList.remove("mobile-hide-info");
    }
    setHiddenLength(hiddenBrands.length);
  };

  const handleBrandInfoClick = (location, locMarker, brandId) => {
    handleAmplitude("click_list_content");
    if (!map.getBounds().contains(locMarker.getPosition())) {
      setCenter(locMarker.getPosition());
      let searchLoc = {};
      searchLoc.isSearch = true;
      searchLoc.isLoc = true;
      searchLoc.location = [
        location.location.latitude,
        location.location.longitude,
      ];
      setUserLocation(searchLoc);
    }
    loadingDetails = true;
    setListState(2);
    setCurBrand(brandId);
    setCurLoc(location);
    handleCurMarker(locMarker);
  };

  //create search functionality (this lives outside the react app)
  const initAutoComplete = () => {
    // Create the search box and link it to the UI element.
    const input = document.getElementById("pac-input");
    const searchBox = new google.maps.places.SearchBox(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", () => {
      searchBox.setBounds(google.maps.LatLngBounds(map.getBounds()));
    });

    input.addEventListener("keyup", (event) => {
      let custom = document.getElementsByClassName("pac-custom");
      while (custom.length > 0) {
        custom[0].remove();
      }
      let markerPlaces;
      if (input.value.length > 2) {
        markerPlaces = markersState.filter((marker) =>
          marker.locName
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(input.value.toUpperCase().trim()),
        );
        const lengthToUse = markerPlaces?.length > 5 ? 5 : markerPlaces.length;
        const toAppend = document.getElementsByClassName("pac-container")[0];
        toAppend.classList.add(
          widget === true && colorMode === "light"
            ? "light-pac-container"
            : colorMode === "black"
              ? "black-pac-container"
              : "dark-pac-container",
        );
        for (let i = 0; i < lengthToUse; i++) {
          let hero =
            brands[markerPlaces[i].brand_id].branding.hero_image.value.indexOf(
              ".png",
            ) !== -1
              ? brands[
                  markerPlaces[i].brand_id
                ].branding.hero_image.value.replace(".png", ".jpg")
              : brands[markerPlaces[i].brand_id].branding.hero_image.value;
          let pacItem = document.createElement("div");
          pacItem.classList.add("pac-item");
          pacItem.classList.add("pac-custom");
          pacItem.innerHTML = `<div class="pac-item-bg" style="background-image: url('${cloudinaryPath}/b_black,o_67,c_fit,w_120/${hero}')">
              <img src="${cloudinaryPath}/w_120/${
                brands[markerPlaces[i].brand_id].branding.logo.dark_mode
              }"/>
            </div>
            <div class="pac-text">
              <div class="pac-loc-name">${markerPlaces[i].locName}</div>
              <div class="pac-address">${markerPlaces[i].address}</div>
            </div>`;
          toAppend.appendChild(pacItem);

          pacItem.addEventListener("mousedown", () => {
            handleAmplitude("click_web_map_content");
            let loc = markerPlaces[i].location;
            setCenter(markerPlaces[i].position);
            setListState(2);
            setCurBrand(loc.brand_id);
            setCurLoc(loc);

            handleCurMarker(markerPlaces[i].location.marker);
            input.value = "";
            toAppend.innerHTML = "";
            let searchLoc = {};
            searchLoc.isSearch = true;
            searchLoc.isLoc = true;
            searchLoc.location = [
              markerPlaces[i].position.lat(),
              markerPlaces[i].position.lng(),
            ];
            setUserLocation(searchLoc);
          });
        }
      }
    });

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", () => {
      handleAmplitude("click_web_map_content");
      const places = searchBox.getPlaces();
      if (places.length == 0) {
        return;
      }
      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();
      input.value = "";
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        }
        let searchLoc = {};
        setListState(0);
        searchLoc.isSearch = true;
        searchLoc.isLoc = false;
        searchLoc.location = [
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ];
        setUserLocation(searchLoc);
        const toAppend = document.getElementsByClassName("pac-container")[0];
        toAppend.innerHTML = "";

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
      setCenter(map.getCenter());
    });
  };

  //fetch user data and set up listeners
  const handleInit = () => {
    const popup = document.getElementById("pass-popup");
    const hamburgerMenu = document.getElementsByClassName("hamburger-menu")[0];
    const popupClose = document.getElementById("popup-close");
    const body = document.getElementsByTagName("body")[0];

    body.style.background = "transparent";

    if (isHamburgerRequired) {
      hamburgerMenu.addEventListener("click", () => {
        hamburgerStateRef.current === false
          ? setHamburgerState(true)
          : setHamburgerState(false);
      });
    }

    let url = window.location.hostname.includes("localhost")
      ? "http://app.localhost:3000/"
      : window.location.hostname.includes("-staging")
        ? "https://app.inkind-staging.com/"
        : "https://app.inkind.com/";

    fetch(url + "api/v4/ip_location")
      .then((response) => response.json())
      .then((ip) => {
        setUserLocation(ip), setStaticLocation(ip);
      })
      .catch((err) => setUserLocation("not found"));
  };

  const getDistance = (lng, lat, trueCenter, isStatic) => {
    const ruler = new CheapRuler(userLocation.location[0] ?? 40.73, "miles");
    const distance = ruler.distance(
      [
        Math.round(
          (!!isStatic ? staticLocation.location[1] : trueCenter.lng()) * 10,
        ) / 10,
        Math.round(
          (!!isStatic ? staticLocation.location[0] : trueCenter.lat()) * 10,
        ) / 10,
      ],
      [Math.round(lng * 10) / 10, Math.round(lat * 10) / 10],
    );
    let rounded =
      distance > 100 ? Math.round(distance) : Math.round(distance * 10) / 10;
    return rounded;
    return null;
  };

  const handleBuild = (useLocs, buildMarkers, locations, markers) => {
    const trueCenter = getTrueCenter(
      [userLocation?.location[0] ?? 40.73, userLocation?.location[1] ?? -74],
      map,
    );

    for (let i = 0; i < locations.length; i++) {
      let useDistance = getDistance(
        locations[i].location.longitude,
        locations[i].location.latitude,
        trueCenter,
        false,
      );
      let staticDistance = getDistance(
        locations[i].location.longitude,
        locations[i].location.latitude,
        trueCenter,
        true,
      );
      locations[i].distance = useDistance;
      locations[i].latlng =
        String(locations[i].location.latitude) +
        String(locations[i].location.longitude);
      if (!!buildMarkers) {
        locations[i].staticDistance = staticDistance;
      }
    }
    let sortedLocs = locations.sort((a, b) => a.distance - b.distance);
    if (
      !!curLoc &&
      !!userLocation?.isLoc &&
      sortedLocs[0].location_id !== curLoc.location_id
    ) {
      for (let i = 0; i < sortedLocs.length; i++) {
        if (curLoc.location_id === sortedLocs[i].location_id) {
          sortedLocs.unshift(sortedLocs.splice(i, 1)[0]);
        }
      }
    }
    setLocations(sortedLocs);
    if (!curMarker || curMarker.isMulti !== true) {
      setFilteredLocations(sortedLocs);
    }
    if (!!buildMarkers) {
      let latLngs = {};

      for (let i = 0; i < sortedLocs.length; i++) {
        let locString =
          String(sortedLocs[i].location.latitude) +
          String(sortedLocs[i].location.longitude);
        if (!!latLngs[locString]) {
          latLngs[locString].push(i);
        } else {
          latLngs[locString] = [i];
        }

        let marker = new google.maps.Marker({
          position: {
            lat: sortedLocs[i].location.latitude,
            lng: sortedLocs[i].location.longitude,
          },
          address: `${sortedLocs[i].location.address}, ${sortedLocs[i].location.city}, ${sortedLocs[i].location.state}, USA`,
          isMulti: false,
          labelOrigin: new google.maps.Point(0, -20),
          location: sortedLocs[i],
          locationNumber: i,
          locName: sortedLocs[i].name,
          brand_id: sortedLocs[i].brand_id,
          groupData: sortedLocs[i].group_id,
          label: "",
          icon: {
            url: oval,
            labelOrigin: new google.maps.Point(15.5, 14),
          },
          map: map,
          multiLocations: 0,
          optimized: false,
          saveZIndex: i,
          zIndex: i,
        });
        sortedLocs[i].marker = marker;
        markers.push(marker);
      }

      //filter back through and assign multi-marker status to ones with the same coordinates
      for (let coord in latLngs) {
        let specificAddress = latLngs[coord];
        if (specificAddress.length > 1) {
          for (let i = 0; i < specificAddress.length; i++) {
            markers[specificAddress[i]].isMulti = true;
            markers[specificAddress[i]].multiLoc = coord;
            markers[specificAddress[i]].multiLocations = specificAddress.length;
            markers[specificAddress[i]].setLabel({
              text: String(markers[specificAddress[i]].multiLocations),
              className: "map-v2-label",
            });
            if (i !== 0) {
              markers[specificAddress[i]].setVisible(false);
              markers[specificAddress[i]].location.marker =
                markers[specificAddress[0]];
            }
          }
        }
      }

      if (showSearchBox) addListeners(markers, sortedLocs);
      setMarkersState(markers);
    } else {
      const sortedMarkers = markersState.sort(
        (a, b) => a.location.distance - b.location.distance,
      );
      if (userLocation?.isLoc === false) {
        setListState(1);
      }
      if (showSearchBox) addListeners(sortedMarkers, sortedLocs);
      setMarkersState(sortedMarkers);
    }
  };

  //build and/or modify markers
  const handleLocsAndMarkers = (useLocs, buildMarkers) => {
    locations = !!useLocs ? useLocs : locations;
    let markers = [];
    if (locations === null || !map) {
      return;
    }
    if (!!map.getProjection()) {
      handleBuild(useLocs, buildMarkers, locations, markers);
    } else {
      google.maps.event.addListenerOnce(map, "idle", function () {
        handleBuild(useLocs, buildMarkers, locations, markers);
      });
    }
  };

  const addListeners = (markers, locs) => {
    for (let i = 0; i < markers.length; i++) {
      google.maps.event.clearInstanceListeners(markers[i]);
      google.maps.event.addListener(markers[i], "click", () => {
        handleAmplitude("click_web_map_content");
        if (curMarkerRef?.current?.locName === markers[i].locName) {
          setFilteredLocations(locationsRef.current);
          setListState(1);
          setCurLoc(null);
          handleCurMarker(null);
          setCurBrand(0);
        } else if (markers[i].isMulti === true) {
          setInfoOpenMobile(true);
          setListState(1);
          setCurLoc(null);
          handleCurMarker(markers[i]);
          setCurBrand(0);
          handleFilter("latlng", markers[i].multiLoc, locs);
        } else {
          if (listStateRef.current === 2) {
            document.getElementsByClassName("loc-tile")[0].src = "";
            document.getElementsByClassName("loc-logo")[0].src = "";
          }
          setCurBrand(markers[i].location.brand_id);
          setCurLoc(markers[i].location);
          handleCurMarker(markers[i]);
          setListState(2);
          let container = document.getElementsByClassName(
            "info-container-inner",
          )[0];
          container.scrollTop = 0;
        }
      });
    }
    return markers;
  };

  const handleCloseMenus = (e) => {
    if (!!e.target.closest(".menu-items")) {
      return;
    }
    !!moodsIsOpen ? setMoodsIsOpen(false) : null;
    !!cuisinesIsOpen ? setCuisinesIsOpen(false) : null;
  };

  const handleBuildTags = (tags) => {
    const tagsObj = {};
    for (let i = 0; i < tags.length; i++) {
      tagsObj[tags[i].id] = tags[i];
    }
    setTags(tagsObj);
  };

  //deal with brand/group/location data returned
  const handleSuccess = (data) => {
    let brandsList = {};
    for (let i = 0; i < data.brands.length; i++) {
      brandsList[data.brands[i].brand_id] = data.brands[i];
    }
    const filteredLocations = showDeliveryOnly
      ? data.locations.filter((loc) => {
          return brandsList?.[loc.brand_id]?.tags?.some(
            (tag) => tag.id === 10000,
          );
        })
      : data.locations;
    setBrands(brandsList);
    setHiddenLength(data.brands.length - 15);
    setInitLocations(filteredLocations);
    handleLocsAndMarkers(filteredLocations, true);
    handleBuildTags(data.tags);
  };

  //ui
  return (
    <div className="outer" onClick={(e) => handleCloseMenus(e)}>
      {showSearchBox && (
        <MapHeaderContainer
          infoOpenMobile={infoOpenMobile}
          setInfoOpenMobile={setInfoOpenMobile}
          hamburgerState={hamburgerState}
          widget={widget}
          setUserLocation={setUserLocation}
          staticLocation={staticLocation}
          userLocation={userLocation}
          map={map}
          setEnteredThroughButton={setEnteredThroughButton}
          colorMode={colorMode}
          selectedCuisines={selectedCuisines}
          setSelectedCuisines={setSelectedCuisines}
          selectedMoods={selectedMoods}
          setSelectedMoods={setSelectedMoods}
          moodsIsOpen={moodsIsOpen}
          cuisinesIsOpen={cuisinesIsOpen}
          setMoodsIsOpen={setMoodsIsOpen}
          setCuisinesIsOpen={setCuisinesIsOpen}
          placeHolder={placeHolder}
          initSelectedTag={initSelectedTag}
          logo={logo}
          ikname={ikname}
          inkindStateDomain={inkindStateDomain}
        />
      )}
      <div
        className="map-outside"
        style={{
          display: !!isLoaded ? "block" : "none",
          borderRadius: widget === true ? "24px" : "0px",
          border: widget === true ? "2px solid #ccc" : "0px",
        }}
      >
        {showSidebar && (
          <div
            className="info-container"
            style={{ borderRadius: widget === true ? "24px" : "" }}
          >
            <div className="info-container-inner">
              <AnimatePresence>
                {listState === 1 && (
                  <motion.div
                    key="listState1"
                    className="locations-list"
                    id="list-1"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    {filteredLocations?.length !== locations?.length && (
                      <BackSection
                        widget={widget}
                        handleExitMulti={handleExitMulti}
                        colorMode={colorMode}
                      />
                    )}
                    {!!brands &&
                      filteredLocations?.map((location, i) => {
                        if (!usedBrandsObj[location.brand_id]) {
                          usedBrandsObj[location.brand_id] = true;
                          counter = counter + 1;
                          return (
                            <BrandInfo
                              location={location}
                              brandToUse={brands[location.brand_id]}
                              i={i}
                              counter={counter}
                              cloudinaryPath={cloudinaryPath}
                              userLocErr={userLocErr}
                              handleBrandMouseIn={handleBrandMouseIn}
                              handleBrandMouseOut={handleBrandMouseOut}
                              handleBrandInfoClick={handleBrandInfoClick}
                              key={location.name}
                              setHiddenLength={setHiddenLength}
                              tags={tags}
                              getFeaturedTags={getFeaturedTags}
                            />
                          );
                        }
                      })}
                    {!!hiddenLength && !curMarker && (
                      <div
                        className="show-more-brands"
                        onClick={() => {
                          handleShowMoreBrands();
                        }}
                      >
                        <div className="show-more">
                          <img src={downArrow} />
                          Show {hiddenLength > 15 ? 15 : hiddenLength} more
                        </div>
                      </div>
                    )}
                  </motion.div>
                )}
                {listState === 2 && (
                  <motion.div
                    key="listState2"
                    className="brand-info"
                    id="list-2"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <BackSection
                      widget={widget}
                      handleBack={handleBack}
                      colorMode={colorMode}
                    />

                    <BrandDetails
                      brandToUse={brands[curBrand]}
                      curLoc={curLoc}
                      tiers={tiers}
                      cloudinaryPath={cloudinaryPath}
                      userLocErr={userLocErr}
                      ctaDisplay={ctaDisplay}
                      tags={tags}
                      getFeaturedTags={getFeaturedTags}
                      purchaseModeTiers={purchaseModeTiers}
                      brands={brands}
                      totalLocationCount={totalLocationCount}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <AnimatePresence>
              {listState === 2 && !!showBonusBox && ctaDisplay !== "none" && (
                <motion.div
                  className="bonus-scroll-box"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  id="bonus-box"
                  exit={{ opacity: 0 }}
                  onClick={handleBonusScroll}
                >
                  {!!purchaseModeTiers ? (
                    tiers?.length ? (
                      <span>
                        Get a ${tiers[0].rounded_bonus_amount} to $
                        {tiers[tiers.length - 1].rounded_bonus_amount} bonus
                      </span>
                    ) : null
                  ) : (
                    <span>Get 20% Back Here</span>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
        <AnimatePresence>
          {!!curBrand && !!curLoc && infoOpenMobile === false && (
            <motion.div
              className="mobile-brand-info"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <BrandInfo
                location={curLoc}
                brandToUse={brands[curLoc.brand_id]}
                i={0}
                cloudinaryPath={cloudinaryPath}
                userLocErr={userLocErr}
                handleBrandMouseIn={handleBrandMouseIn}
                handleBrandMouseOut={handleBrandMouseOut}
                handleBrandInfoClick={handleBrandInfoClick}
                key={curLoc.name}
                tags={tags}
                getFeaturedTags={getFeaturedTags}
              />
              <a
                href="#"
                onClick={() => {
                  infoOpenMobile === false
                    ? setInfoOpenMobile(true)
                    : setInfoOpenMobile(false);
                }}
              >
                <div className="pass-button">
                  Learn More
                  <img src={expand} alt="inKind Pass" className="arrow-logo" />
                </div>
              </a>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {!!popUpOpen &&
            !isPassPage &&
            ctaDisplay !== "none" &&
            showPassPopup && (
              <PassPopup
                infoOpenMobile={infoOpenMobile}
                curLoc={curLoc}
                curBrand={curBrand}
                popUpOpen={popUpOpen}
                setPopUpOpen={setPopUpOpen}
                purchaseModeTiers={purchaseModeTiers}
                maxBonus={
                  tiers.length
                    ? tiers[tiers.length - 1].rounded_bonus_amount
                    : 0
                }
                totalLocationCount={totalLocationCount}
              />
            )}
        </AnimatePresence>
        {!!map && !infoOpenMobile && showZoombox && (
          <ZoomBox map={map} setCenter={setCenter} viewCenter={viewCenter} />
        )}
        <div
          id="map-container"
          className={
            widget === true ? "widget-container" : "non-widget-container"
          }
        ></div>
      </div>
    </div>
  );
};

export default withEventsLogger(App);

App.propTypes = {
  tiers: PropTypes.array,
  cloudinaryPath: PropTypes.string,
  widget: PropTypes.bool,
  enterprise: PropTypes.string,
  totalLocationCount: PropTypes.number,
  brandSlug: PropTypes.string,
  showSearchBox: PropTypes.bool,
  showSidebar: PropTypes.bool,
  showZoombox: PropTypes.bool,
  isHamburgerRequired: PropTypes.bool,
  showPassPopup: PropTypes.bool,
};
