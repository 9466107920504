/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import MainHeader, { BackButton } from "./desktop/HeaderDesktop";
import PropTypes from "prop-types";

export default function EmailHeader({ number, setCurPage }) {
  return (
    <MainHeader>
      <div>
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-bottom: 30px;
          `}
        >
          <BackButton setCurPage={setCurPage} />
          <h1
            className="capture-db__header-name"
            css={css`
              font-weight: 600;
              font-size: 20px;
              color: #2e2e2d;
              text-align: center;
              flex: 1;
              padding-right: 8px;
            `}
          >
            Enter your email
          </h1>
        </div>
      </div>
    </MainHeader>
  );
}

EmailHeader.propTypes = {
  number: PropTypes.any,
  setCurPage: PropTypes.func,
};
