import React from "react";
import { motion } from "framer-motion";
import SubmitButton from "./SubmitButton";
import LogoutButton from "./LogoutButton";
import ikLogo from "../../../assets/images/checkout/inKind_logo.svg";
import { useFormikContext } from "formik";
import { formatPhoneNumber } from "@lib/helpers";
import { isMobileDevice } from "../ambassador/organisms/helpers";
import EmailHeader from "../ambassador/2fa-user-flow/EmailHeader";
import MainHeader, {
  AddPhoneNumberHeader,
  GenericHeader,
  OTPCodeHeader,
  BackButton,
} from "../ambassador/2fa-user-flow/desktop/HeaderDesktop";
import { VerificationCodeHeader } from "../ambassador/2fa-user-flow/VerificationCode";
import { AddPhoneNumberHeader as Signup2faFlowHeader } from "../ambassador/2fa-user-flow/SignUpScreen";
import PropTypes from "prop-types";

const LoginCePageWrapper = ({
  children,
  curPage,
  setCurPage,
  isBslCheckout,
  error,
  handleBack,
  isRailsCheckout,
  handleResend,
  isPartnerPortal,
  isAmbassadorFlow,
  accentColor,
  show = false,
  setStep,
}) => {
  const { values } = useFormikContext();
  const [showResend, setShowResend] = React.useState(false);
  const [success, setSuccess] = React.useState("");
  React.useEffect(() => {
    if (curPage === 7) {
      const trackTime = setTimeout(() => {
        setShowResend(true);
      }, 60000);

      //clear timeout on unmount
      return () => {
        clearTimeout(trackTime);
      };
    }
  });

  React.useEffect(() => {
    if (success.length > 0) {
      setShowResend(false);
      const trackTime = setTimeout(() => {
        setSuccess("");
        setShowResend(true);
      }, 60000);

      //clear timeout on unmount
      return () => {
        clearTimeout(trackTime);
      };
    }
  }, [success]);
  const is2faFlow = isAmbassadorFlow && location?.pathname?.length > 1;
  //is this user creating an account, using 2FA for the first time, or a normal returning user?

  //What should the view's header display?
  const loginHeaderText = {
    0: isAmbassadorFlow ? "" : "Sign In or Sign Up",
    1: isAmbassadorFlow ? "" : "Sign In or Sign Up",
    2: isAmbassadorFlow ? "Enter verification code" : "Verify your phone",
    3: "Enter your email",
    4: "Complete your inKind account",
    5: "Sign In",
    6: "Verify another way to proceed",
    7: "Verify your email",
  };

  const loginSubHeaderText4Content =
    isAmbassadorFlow && isMobileDevice()
      ? "You'll redeem your balance in-store using the inKind app."
      : "";

  const parentCOnditionalClassName = isMobileDevice()
    ? `mobile-login-ca_container-${curPage}`
    : `desktop-login-ca_container-${curPage}`;

  const loginSubHeaderText = {
    0: isPartnerPortal
      ? "Please Sign in or sign up with your mobile phone number."
      : "",
    1: isPartnerPortal
      ? "Please Sign in or sign up with your mobile phone number."
      : "",
    2: `${isAmbassadorFlow ? "We texted a code to " : "Enter the 6-digit code we sent to "}${formatPhoneNumber(values.phone)}.`,
    3: isPartnerPortal ? "Please enter your email." : "",
    4: isPartnerPortal
      ? "Please create an account with the email address you received your invite from."
      : loginSubHeaderText4Content,
    5: "",
    6: "",
    7: `When you have a moment, check your ${values.email} inbox for a link to verify your email address.`,
  };

  const assembleBody = () => {
    return encodeURIComponent(
      `Hello,\n\nI'm having trouble verifying my email address (${values.email}) while logging in to inKind at the following url:\n\n${window.location.href}`,
    );
  };
  const phoneNumberScreenComponentForAmbassador =
    is2faFlow && isMobileDevice() ? (
      <MainHeader>
        <Signup2faFlowHeader setCurPage={setCurPage} />
      </MainHeader>
    ) : (
      <MainHeader>
        <AddPhoneNumberHeader setCurPage={setCurPage} />
      </MainHeader>
    );
  const verificationScreenContentForAmbassador =
    isMobileDevice() && is2faFlow ? (
      <MainHeader>
        <VerificationCodeHeader
          setCurPage={setCurPage}
          number={formatPhoneNumber(values.phone)}
          accentColor={accentColor}
        />
      </MainHeader>
    ) : (
      <MainHeader>
        <OTPCodeHeader
          setCurPage={setCurPage}
          number={formatPhoneNumber(values.phone)}
        />
      </MainHeader>
    );
  const verbNextContent = isAmbassadorFlow ? "Next" : "Continue";
  return (
    <motion.div
      key={`page-${curPage}`}
      className={`login-ca__container inner-col ${isAmbassadorFlow ? parentCOnditionalClassName : ""} ${
        curPage === 7 || curPage === 6 ? "login-ca__confirm" : ""
      }`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      {curPage < 2 && !!isBslCheckout ? (
        <img className="login-ca__ik-logo" src={ikLogo} alt="InKind Logo" />
      ) : null}
      {(curPage > 1 && curPage < 8) || isBslCheckout ? (
        <div
          className={`login-ca__back-container ${
            isBslCheckout ? "login-ca__back-container--bsl" : ""
          }`}
        >
          {curPage > 1 && curPage < 8 ? (
            <button
              className="login-ca__back-icon"
              type="button"
              alt="Back one step"
              onClick={() => {
                handleBack();
              }}
            />
          ) : null}
          {isBslCheckout ? (
            <h3 className="login-ca__header login-ca__head">
              {loginHeaderText[curPage]}
            </h3>
          ) : null}
          {curPage === 7 ? (
            <a
              className="login-ca__help-button"
              href={`mailto:support@inkind.com?subject=I%27m%20having%20trouble%20verifying%20my%20email&body=${assembleBody()}`}
            >
              Help
            </a>
          ) : null}
        </div>
      ) : null}
      {curPage < 2 && isAmbassadorFlow
        ? phoneNumberScreenComponentForAmbassador
        : null}
      {curPage === 7 ? children : null}
      {curPage === 2 && isAmbassadorFlow
        ? verificationScreenContentForAmbassador
        : null}
      {(curPage === 6 || curPage === 5 || curPage === 4) &&
      isAmbassadorFlow &&
      isMobileDevice() ? (
        <BackButton setCurPage={setCurPage} />
      ) : null}
      {!isBslCheckout &&
      curPage === 3 &&
      isAmbassadorFlow &&
      isMobileDevice() ? (
        <MainHeader>
          <EmailHeader setCurPage={setCurPage} />
        </MainHeader>
      ) : null}
      {(curPage > 2 && isAmbassadorFlow && !is2faFlow) ||
      (curPage > 2 && is2faFlow && !isMobileDevice()) ? (
        <MainHeader>
          <GenericHeader setCurPage={setCurPage} />
        </MainHeader>
      ) : null}
      {(!isBslCheckout && !isAmbassadorFlow) ||
      (!isBslCheckout && curPage > 2 && isAmbassadorFlow) ? (
        <h3
          className={`login-ca__header login-ca__head ${
            curPage === 7 || curPage === 6 ? "center-header" : ""
          }`}
        >
          {loginHeaderText[curPage]}
        </h3>
      ) : null}
      {(loginSubHeaderText[curPage]?.length > 0 && !isAmbassadorFlow) ||
      (isAmbassadorFlow &&
        curPage > 2 &&
        loginSubHeaderText[curPage]?.length > 0) ? (
        <div className="login-ca__subhead">{loginSubHeaderText[curPage]}</div>
      ) : null}
      {curPage === 2 ? (
        <div className="login-ca__subhead">
          {isAmbassadorFlow ? (
            <p>Didn't get a code? </p>
          ) : (
            `Didn't get a code? `
          )}
          <button className="login-ca__verify" onClick={() => setCurPage(6)}>
            {isAmbassadorFlow ? "Tap to text us instead" : "Verify another way"}
          </button>
        </div>
      ) : null}
      {curPage !== 7 ? children : null}
      {error?.length > 0 ? <div className="error">{error}</div> : null}
      {curPage !== 6 && curPage !== 8 ? (
        <SubmitButton
          verb={curPage === 7 ? "I have verified my email" : verbNextContent}
          isBslCheckout={isBslCheckout}
          curPage={curPage}
        />
      ) : null}
      {curPage === 8 && isRailsCheckout ? <LogoutButton /> : null}
      {curPage === 7 && showResend ? (
        <div className="login-ca__subhead">
          Didn't get an email?{" "}
          <button
            className="login-ca__verify"
            type="button"
            onClick={() => {
              handleResend().then((resp) => {
                if (resp.message) {
                  setSuccess(resp.message);
                }
              });
            }}
          >
            Resend email
          </button>
        </div>
      ) : null}
      {curPage === 7 && success?.length > 0 ? (
        <div className="success">{success}</div>
      ) : null}
    </motion.div>
  );
};

export default LoginCePageWrapper;

LoginCePageWrapper.propTypes = {
  children: PropTypes.any,
  curPage: PropTypes.number,
  setCurPage: PropTypes.func,
  isBslCheckout: PropTypes.bool,
  error: PropTypes.any,
  handleBack: PropTypes.func,
  isRailsCheckout: PropTypes.bool,
  handleResend: PropTypes.func,
  isPartnerPortal: PropTypes.bool,
  isAmbassadorFlow: PropTypes.bool,
  accentColor: PropTypes.string,
  show: PropTypes.bool,
  setStep: PropTypes.func,
};
