/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useState } from "react";
import SignUp from "./Signup";
import SignUpScreen from "./SignUpScreen";
import GeneratedLink from "./GeneratedLink";
import Error from "./Error";
import { Provider } from "react-redux";
import { store } from "../../newCheckout/redux/store";
import UserFlowDesktop from "./desktop";

export function isMobileDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    );

  return isMobile;
}

export function calculateFontColorFor(backgroundColor) {
  if (!backgroundColor || backgroundColor.length < 1) {
    return "";
  }
  var hex = backgroundColor.replace(/#/, "");
  var r = parseInt(hex.slice(0, 2), 16);
  var g = parseInt(hex.slice(2, 4), 16);
  var b = parseInt(hex.slice(4, 6), 16);
  var color = (299 * r + 587 * g + 114 * b) / 1000;
  if (color >= 145) {
    return "#000000";
  } else {
    return "#FFFFFF";
  }
}

export default function UserFlow({ initialValues }) {
  const [step, setStep] = useState(1);
  const [user, setUser] = useState({ user: null, shouldShowToast: false });
  const [accentColor, setAccentColor] = useState(initialValues?.accentColor);
  const brandSlug = window.location.pathname.split("/")[1];
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <Provider store={store}>
      <div
        className={`ambassador-parent-common ${isModalOpen ? "hide-background" : ""}`}
      >
        {brandSlug && initialValues?.status !== "disabled" ? (
          isMobileDevice() ? (
            <div
              className="fa-user-flow"
              css={css`
                max-width: 600px;
                margin: 0 auto;
                text-align: center;
                min-height: 100vh;
              `}
            >
              {step === 1 && (
                <SignUp initialValues={initialValues} setStep={setStep} />
              )}
              {step === 2 && (
                <SignUpScreen
                  initialValues={initialValues}
                  accentColor={accentColor}
                  setStep={setStep}
                  setUser={setUser}
                />
              )}
              {step === 3 && (
                <GeneratedLink
                  setStep={setStep}
                  user={user}
                  brandSlug={brandSlug}
                  initialValues={initialValues}
                  setIsModalOpen={setIsModalOpen}
                  isModalOpen={isModalOpen}
                />
              )}
            </div>
          ) : (
            <UserFlowDesktop
              initialValues={initialValues}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          )
        ) : (
          <Error
            brandName={initialValues?.brandName || ""}
            disabled={initialValues?.status === "disabled"}
          />
        )}
      </div>
    </Provider>
  );
}
