/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React from "react";
import TopSection from "./TopSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import NewLoginCreateAccount from "../../new-login-create-account/NewLoginCreateAccount";

export default function SignUpScreen({
  setStep,
  setUser,
  initialValues,
  accentColor,
}) {
  return (
    <div
      className="signup-screen"
      css={css`
        min-height: 100vh;
      `}
    >
      <TopSection initialValues={initialValues} />
      <div
        className="container"
        css={css`
          width: 100%;
        `}
      >
        <div>
          <NewLoginCreateAccount
            country={"US"}
            setUser={setUser}
            siteArea={"ambassador"}
            setStep={setStep}
            accentColor={accentColor}
          />
        </div>
      </div>
    </div>
  );
}

export function MainHeader({ children }) {
  return <div className="main-header">{children}</div>;
}

export function AddPhoneNumberHeader({ setCurPage }) {
  return (
    <>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 8px;
        `}
      >
        <BackButton setCurPage={setCurPage} />
        <h1
          className="capture-db__header-name"
          css={css`
            font-weight: 600;
            font-size: 20px;
            color: #2e2e2d;
            text-align: center;
            flex: 1;
            padding-right: 18px;
          `}
        >
          Sign up with your phone
        </h1>
      </div>
      <p
        css={css`
          margin-bottom: 30px;
          font-size: 14px;
        `}
      >
        We’ll use your phone to enable your account
      </p>
    </>
  );
}

export function BackButton({ setCurPage = null }) {
  return (
    <button
      css={css`
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
      `}
      className="back-button"
      onClick={() =>
        setCurPage((curPage) =>
          curPage === 5 ? 3 : curPage === 6 ? 2 : curPage - 1,
        )
      }
    >
      <FontAwesomeIcon icon={faArrowLeft} />
    </button>
  );
}
